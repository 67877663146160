
function init()
{


    $('.btn[disabled="disabled"]').on('click', function(evt){
        evt.preventDefault();
    });



    $('.del-confirm').on('click', function(evt){
        
        evt.preventDefault();

        if ($(this).hasClass('del-confirmed')) {
            document.location.href = $(this).attr('href');
            return;
        }



        var b = confirm('Confirma a exclusão?');

        if( b ) {

            $(this).addClass('del-confirmed');
            $(this).click();

        }

    });

}

$(document).ready(init);